import React from "react";
import ContactForm from "../minor/contactForm";

function ContactFormContent() {
  return (
    <div className="modal-body-container contactForm">

        <ContactForm></ContactForm>
        
    </div>
  );
}

export default ContactFormContent;
